import classNames from 'classnames';
import type { FC } from 'react';
import { useRef } from 'react';
import React, { useState, useContext } from 'react';

import Address from '../../../components/Address';
import AppointmentItem from '../../../components/AppointmentItem';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import Image from '../../../components/ui/Image';
import { findClosestDates } from '../../../lib/filter';
import ConfigVosContext from '../../../service/context/config-vos';
import useAppStoreVos from '../../../service/store/appVos';
import useOrderStoreVos from '../../../service/store/orderVos';
import { validateOrderVos } from '../../../utils/validate';

import AppointmentFilter from './AppointmentFilter';
import './AppointmentList.scss';

interface IAppointmentListProps {
  placeReminder?: () => Promise<void>;
}

const AppointmentList: FC<IAppointmentListProps> = ({
  placeReminder
}) => {
  const errorRef = useRef<null | HTMLDivElement>(null);
  const [ showErrors, setShowErrors ] = useState(false);
  const [ tab, setTab ] = useState(0);
  const [ showReminderForm, setShowReminderForm ] = useState(false);
  const { filter, setFilter, setStep } = useAppStoreVos(state => state);
  const {
    order,
    setValue: setOrderValue,
    setAppointment: setOrderAppointment,
    setDealer: setOrderDealer,
    setDealers: setOrderDealers
  } = useOrderStoreVos(state => state);
  const { date, search, city, customer } = filter;
  const appContext = useContext(ConfigVosContext);
  const multiCheckbox = true;
  const { appointments, dealers, delivery_countries } = appContext || {};
  const filteredDealers = dealers?.filter(dealer => {
    if (
      search
      && (
        !dealer.customer.toLowerCase().includes(search.toLowerCase())
        && !dealer.address.toLowerCase().includes(search.toLowerCase())
      )
    ) {
      return false;
    }

    return true;
  });
  const filteredAppointments = appointments?.filter(appointment => {
    if (
      search
      && (
        !appointment.customer.toLowerCase().includes(search.toLowerCase())
        && !appointment.address.toLowerCase().includes(search.toLowerCase())
      )
    ) {
      return false;
    }

    if (city && appointment.city !== city) {
      return false;
    }

    if (customer && appointment.customer !== customer) {
      return false;
    }

    return true;
  });
  const filterByDate = date
    ? findClosestDates(filteredAppointments, date) : filteredAppointments;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    setOrderValue(event.target.value, event.target.name);
  };

  const selectedDealer = order?.dealer;
  const validOrder = validateOrderVos(order, true);

  const handleCtaClick = (): void => {
    if (validOrder && placeReminder) {
      void placeReminder();
    } else {
      setShowErrors(true);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      {(!selectedDealer && !showReminderForm) && (
        <>
          <div className="appointment-list-navi">
            <div className="tab-navi-wrap">
              <div className="tab-navi tab-navi-wrap">
                <button
                  className={classNames('btn', {
                    inactive: tab !== 0
                  })}
                  onClick={() => {
                    setTab(0);
                    setFilter('city', '');
                    setFilter('customer', '');
                    setOrderDealers(null);
                  }}
                >
                  Einen Termin suchen
                </button>
                <button
                  className={classNames('btn', {
                    inactive: tab !== 1
                  })}
                  onClick={() => {
                    setTab(1);
                    setOrderDealers(null);
                  }}
                >
                  Anstehende Termine
                </button>
                <button
                  className={classNames('btn', {
                    inactive: tab !== 2
                  })}
                  onClick={() => {
                    setTab(2);
                    setOrderAppointment(undefined);
                  }}
                >
                  Terminerinnerung
                </button>
              </div>
            </div>
            {tab !== 0 && (
              <>
                <div className="filter-item">
                  <div className="filter-item-serach">
                    <Icon icon="search" />
                    <input
                      onChange={event => setFilter('search', event.target.value)}
                      type="text"
                      value={search}
                      placeholder="Name/Adresse..."
                    />
                  </div>
                </div>
                {tab === 2 && multiCheckbox && order?.dealers && (
                  <div className="filter-item">
                    <button
                      onClick={() => setShowReminderForm(true)}
                      className="btn btn-secondary"
                      disabled={order.dealers.length === 0}
                    >
                      An {order.dealers.length} Fachhandel erinnern lassen
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          {tab === 0 && (
            <>
              <div className="vos-intro">
                <div className="title">
                  Wo würdest du gerne unseren Service Beanspruchen?
                </div>
                <div className="filter-item">
                  <div className="filter-item-serach">
                    <Icon icon="search" />
                    <input
                      onChange={event => setFilter('search', event.target.value)}
                      type="text"
                      value={search}
                      placeholder="Dein Fachhandel, Ort oder Postleitzahl?"
                    />
                  </div>
                </div>
                <div className="vos-intro-info">
                  <Icon icon="info" /> Suche nach deinem gewünschten Fachhandel oder Adresse des Fachhandels.
                </div>
                {!search && (
                  <div className="vos-intro-no-text">
                    <Image
                      type="vos"
                      url="vos-empty-search.svg"
                      alt="Bitte Suchfeld benutzen"
                    />
                    <div>
                      Bitte Suchfeld benutzen
                    </div>
                  </div>
                )}
              </div>
              {search && (
                <div className="appointment-list">
                  {filterByDate && filterByDate.length > 0 ? (
                    <div className="appointment-item-list">
                      {filterByDate.map(appointment => (
                        <AppointmentItem
                          onClick={() => {
                            setOrderAppointment(appointment);
                            setOrderDealer(undefined);
                            setStep(2);
                          }}
                          key={`1-${appointment.row}`}
                          date={appointment.date}
                          month={appointment.month}
                          day={appointment.day}
                          title={appointment.customer}
                          address={appointment.address}
                        />
                      ))}
                    </div>
                  ) : (
                    <div>
                      Keine Termine verfügbar
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {tab === 1 && (
            <>
              <AppointmentFilter />
              <div className="appointment-list">
                {filterByDate && filterByDate.length > 0 ? (
                  <div className="appointment-item-list">
                    {filterByDate.map(appointment => (
                      <AppointmentItem
                        onClick={() => {
                          setOrderAppointment(appointment);
                          setOrderDealer(undefined);
                          setStep(2);
                        }}
                        key={`2-${appointment.row}-${appointment.address}`}
                        date={appointment.date}
                        month={appointment.month}
                        day={appointment.day}
                        title={appointment.customer}
                        address={appointment.address}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                  Keine Termine verfügbar
                  </div>
                )}
              </div>
            </>
          )}
          {tab === 2 && (
            <>
              <div className="appointment-list">
                {filteredDealers && filteredDealers.length > 0 ? (
                  <div className="appointment-item-list">
                    {filteredDealers.map(dealer => (
                      <AppointmentItem
                        onClick={() => {
                          if (multiCheckbox) {
                            setOrderDealers(dealer);
                          } else {
                            setOrderDealer(dealer);
                          }
                          setOrderAppointment(undefined);
                        }}
                        key={`3-${dealer.customer}-${dealer.address}-${dealer.internal_customer_number}`}
                        title={dealer.customer}
                        address={dealer.address}
                        multiCheckbox={multiCheckbox}
                        isChecked={order?.dealers?.findIndex(
                          item => item.internal_customer_number === dealer.internal_customer_number
                        ) !== -1}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    Keine Fachhändler verfügbar
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      {(selectedDealer || showReminderForm) && (
        <div className="box-overlay">
          <div className="box-overlay-head">
            {selectedDealer && (
              <AppointmentItem
                title={selectedDealer.customer}
                address={selectedDealer.address}
                standalone
              />
            )}
            {!!order?.dealers?.length && (
              <div className="box-overlay-head-dealers">
                {order?.dealers.map(dealerItem => (
                  <AppointmentItem
                    key={dealerItem.internal_customer_number}
                    title={dealerItem.customer}
                    address={dealerItem.address}
                    standalone
                  />
                ))}
              </div>
            )}
            <div
              className="box-overlay-close"
              onClick={() => {
                setShowReminderForm(false);
                setOrderDealer(undefined);
              }}
            >
              <Icon icon="close" />
            </div>
          </div>
          <div ref={errorRef}>
            {showErrors && !validOrder && (
              <div className="error-box">
                Bitte fülle aller Pflichtfelder aus.
              </div>
            )}
          </div>
          <Address
            showError={showErrors}
            propertyKey="address"
            address={order?.address}
            deliveryCountries={delivery_countries}
            onChange={handleInputChange}
            customerNumber={order?.customer_number}
            showCustomerNumber
            showCountrySelect
          />
          <div className="box-cta">
            <div className="box-cta-hint">
              {/* eslint-disable-next-line max-len */}
              Wir erinnern Sie wenn ein Vor-Ort-Service an diesem Standort stattfindet füllen Sie einfach das folgende
              Formular aus.
            </div>
            <Button
              onClick={handleCtaClick}
              hasRightIcon
              className={classNames('always-visible', {
                'btn-disabled': !validOrder
              })}
              icon="arrow-right"
              // disabled={!validOrder}
            >
              Jetzt erinnern lassen
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentList;
