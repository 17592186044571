import type { FC } from 'react';
import React from 'react';

import AppointmentItem from '../../../components/AppointmentItem';
import useOrderStoreVos from '../../../service/store/orderVos';
import type { IAppointment, IDealer } from '../../../types';

const CartAppointment: FC = () => {
  const { order } = useOrderStoreVos(state => state);
  const { dealer, appointment, dealers } = order || {};

  const selectedDealer = dealer || appointment;

  const isAppointmentType = (data?: IAppointment | IDealer): data is IAppointment => {
    return (data as IAppointment)?.day !== undefined;
  };
  const isAppointment = isAppointmentType(selectedDealer);

  return (
    <div>
      {selectedDealer && (
        <AppointmentItem
          title={selectedDealer?.customer}
          address={selectedDealer?.address}
          day={isAppointment ? selectedDealer.day : ''}
          month={isAppointment ? selectedDealer.month : ''}
          showDate
          standalone
        />
      )}
      {!!dealers?.length && dealers?.map(dealerItem => (
        <AppointmentItem
          key={dealerItem.internal_customer_number}
          title={dealerItem.customer}
          address={dealerItem.address}
          showDate
          standalone
        />
      ))}
      {!selectedDealer && !dealers?.length && (
        (
          <div>
            <em>Kein Händler ausgewählt</em>
          </div>
        )
      )}
    </div>
  );
};

export default CartAppointment;
