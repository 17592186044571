import { produce } from 'immer';
import { set as lSet } from 'lodash';
import { create } from 'zustand';

import type { IAppointment, IDealer, OrderVos } from '../../types';

interface IOrderStateVos {
  nonce?: string;
  order?: OrderVos;
  resetOrderState: () => void;
  setNonce: (nonce: string) => void;
  setValue: (value: string | boolean | number, key: string) => void;
  setAccessories: (accessories: OrderVos['accessories']) => void;
  setJaws: (jaws: OrderVos['jaws']) => void;
  setManufacturer: (manufacturer: OrderVos['manufacturer']) => void;
  setDealer: (dealer: IDealer | undefined) => void;
  setDealers: (dealer: IDealer | undefined | null) => void;
  setAppointment: (appointment: IAppointment | undefined) => void;
}

const initialState = {
  order: {
    // extra setter
    manufacturer: {},
    dealer: undefined,
    dealers: [],
    appointment: undefined,
    checkedLegal: false,
    jaws: {
      jaws_12_35_mm: 0,
      jaws_42_54_mm: 0,
      jaws_64_mm: 0
    },
    accessories: {
      charger: 0,
      case: 0,
      mains_adapter: 0,
      batteries: 0
    },
    // generic setter
    customer_number: '',
    // address setter
    address: undefined
  }
};

const useOrderStoreVos = create<IOrderStateVos>(set => ({
  ...initialState,
  resetOrderState: () => {
    set(initialState);
  },
  // setters
  setNonce: nonce =>
    set(
      produce(draft => {
        draft.nonce = nonce;
      })
    ),
  setValue: (value, key) =>
    set(
      produce(draft => {
        lSet(draft.order, key, value);
      })
    ),
  setDealer: dealer =>
    set(
      produce(draft => {
        draft.order.dealer = dealer;
      })
    ),
  setDealers: dealer => {
    set(state => {
      if (dealer === null) {
        return {
          ...state,
          order: {
            ...state.order,
            dealers: []
          }
        };
      }

      const dealers = state.order?.dealers || []; // Ensure `dealers` is always an array

      // Check if the dealer already exists
      const existingIndex = dealers.findIndex(
        item => item.internal_customer_number === dealer?.internal_customer_number
      );

      let updatedDealers;

      if (existingIndex === -1 && dealer) {
        // Add the new dealer if not already present
        updatedDealers = [ ...dealers, dealer ];
      // eslint-disable-next-line no-negated-condition
      } else if (existingIndex !== -1) {
        // Remove the dealer if it exists
        updatedDealers = dealers.filter(
          item => item.internal_customer_number !== dealer?.internal_customer_number
        );
      } else {
        // If neither condition is met, keep the array unchanged
        updatedDealers = dealers;
      }

      return {
        ...state,
        order: {
          ...state.order,
          dealers: updatedDealers
        }
      };
    });
  },
  setAppointment: appointment =>
    set(
      produce(draft => {
        draft.order.appointment = appointment;
      })
    ),
  setAccessories: accessories =>
    set(
      produce(draft => {
        draft.order.accessories = accessories;
      })
    ),
  setManufacturer: manufacturer =>
    set(
      produce(draft => {
        draft.order.manufacturer = manufacturer;
      })
    ),
  setJaws: jaws =>
    set(
      produce(draft => {
        draft.order.jaws = jaws;
      })
    )
}));

export default useOrderStoreVos;
