import { compact } from 'lodash';
import type { FC } from 'react';

import useAppStoreVos from '../service/store/appVos';

import './Highlighter.scss';

interface IHighlighter {
  text: string;
  disableHighlight?: boolean;
}

const Highlighter: FC<IHighlighter> = ({
  text,
  disableHighlight = false
}) => {
  const { filter } = useAppStoreVos(state => state);
  const { search: searchTerm } = filter;

  if (!searchTerm || disableHighlight) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = compact(text.split(regex));

  return (
    <span className="highlight-wrap">
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          // eslint-disable-next-line react/no-array-index-key
          <span className="highlight-mark" key={`${searchTerm}-${part}-${index}`}>{part}</span>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <span className="highlight-text" key={`${searchTerm}-${part}-${index}`}>{part}</span>
        )
      )}
    </span>
  );
};

export default Highlighter;
